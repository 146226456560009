import logo from './logo.svg';
import './App.css';
import MultiOptionHighlight from './Pages/index';
function App() {
  return (
    <MultiOptionHighlight/>
  );
}

export default App;
